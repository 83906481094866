import React, { useCallback, useEffect } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Dropzone from '../../shared/forms/dropzoneFormik/dropzoneFormik.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import { SelectOptionPropTypes } from '../../shared/forms/propTypes';
import LoadingSpinner from '../../shared/forms/loadingSpinner/loadingSpinner.component';
import { DownloadLink } from '../../shared/displayComponents/downloadLink';
import { errorNotification } from '../../shared/notification/store/actions';
import { useDispatch } from 'react-redux';

import attachementFileNameValidation from './attachementFileNameValidation';

const DOCUMENT_ACCEPTED_TYPES = ['.pdf'];

const validateFileName = file => ({
  valid: attachementFileNameValidation(file.name),
  msg: `This PDF cannot be uploaded because one of the following characters is present in filename:
    /, \\, .. (double periods), ;, |, [, ], <, >, ^, \`, &, ", ', :, ?, *`,
});

const AddEditAttachmentForm = ({
  attachmentDefinitionsOptions,
  businessOptions,
  setFieldValue,
  isSubmitting,
  submitForm,
  resetForm,
  values,
  dirty,
  isEditMode,
  isCreatingOrUpdatingAttachment,
  attachmentDefinitions,
}) => {
  const dispatch = useDispatch();

  const populateUploadFileNameField = useCallback(
    file => {
      setFieldValue('fileName', file.name);
      setFieldValue('pdfFile', file);
    },
    [setFieldValue],
  );

  const onCancelClick = useCallback(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    const attachmentDefinitionObject = attachmentDefinitions.find(
      ({ attachmentDefId }) => attachmentDefId === values.attachmentType,
    );

    if (attachmentDefinitionObject && !isEditMode) {
      setFieldValue('submissionFileName', attachmentDefinitionObject.submissionFileName);
      setFieldValue('attachmentDescription', attachmentDefinitionObject.attachmentDescription);
    }
  }, [attachmentDefinitions, isEditMode, setFieldValue, values.attachmentType]);

  const isPdfFileUploaded = () => {
    return values.pdfFile && values.pdfFile.size > 0;
  };

  const dispatchNotification = (message, duration) => {
    dispatch(
      errorNotification(message, {
        duration,
      }),
    );
  };
  return (
    <form>
      <LoadingSpinner isLoading={isSubmitting || isCreatingOrUpdatingAttachment} />
      <div>
        <Select
          wrapperClassName="form-text"
          appkitLabel="Attachment Type"
          name="attachmentType"
          options={attachmentDefinitionsOptions}
          value={values.attachmentType}
        />
        {businessOptions.length > 0 && (
          <Select
            wrapperClassName="form-text"
            appkitLabel="Business"
            name="businessId"
            options={businessOptions}
            value={values.businessId}
          />
        )}
        <Dropzone
          name="pdfFile"
          onDropCallback={populateUploadFileNameField}
          label="Document"
          acceptTypes={DOCUMENT_ACCEPTED_TYPES}
          existingFileName={values.fileName}
          validateFile={validateFileName}
          value={values.pdfFile}
        />
        <Field
          className="form-text"
          label="File Name"
          name="fileName"
          component={Input}
          autoComplete="off"
        />
        <Field
          className="form-text"
          label="Submission File Name - including file extension"
          name="submissionFileName"
          component={Input}
          autoComplete="off"
        />
        <Field
          className="form-text"
          label="Attachment Description"
          name="attachmentDescription"
          component={Input}
          autoComplete="off"
        />
        {isEditMode && (
          <DownloadLink url={`/api/tools/attachments/download/${values.attachmentId}`}>
            Image
          </DownloadLink>
        )}
        <ButtonsGroup
          disabled={
            !dirty ||
            (values.pdfFile && !values.pdfFile.size) ||
            values.submissionFileName.length > 49
          }
          isSubmitting={isSubmitting}
          onCancelClick={onCancelClick}
          submitButtonLabel="Save"
          submitForm={e => {
            if (isEditMode || isPdfFileUploaded()) {
              submitForm(e);
            } else {
              dispatchNotification('Please upload a PDF file before saving.', 3000);
            }
          }}
        />
      </div>
    </form>
  );
};

AddEditAttachmentForm.propTypes = {
  values: PropTypes.shape({
    fileName: PropTypes.string,
    attachmentType: PropTypes.string,
    businessId: PropTypes.string,
    submissionFileName: PropTypes.string,
    attachmentDescription: PropTypes.string,
    pdfFile: PropTypes.instanceOf(File),
    attachmentId: PropTypes.string,
  }),
  attachmentDefinitionsOptions: PropTypes.arrayOf(SelectOptionPropTypes.isRequired).isRequired,
  businessOptions: PropTypes.arrayOf(SelectOptionPropTypes.isRequired).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    fileName: PropTypes.string,
    attachmentType: PropTypes.string,
    businessId: PropTypes.string,
    submissionFileName: PropTypes.string,
    attachmentDescription: PropTypes.string,
    pdfFile: PropTypes.instanceOf(File),
  }),
  dirty: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isCreatingOrUpdatingAttachment: PropTypes.bool.isRequired,
  attachmentDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      attachmentDefId: PropTypes.string,
      name: PropTypes.string,
      submissionFileName: PropTypes.string,
      attachmentDescription: PropTypes.string,
    }),
  ).isRequired,
};

export default AddEditAttachmentForm;
