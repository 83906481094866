import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { entitySchemas } from '@common-packages/validators';

import { periodSelector, taxYearSelector } from '../../shared/store/selectors';
import Loading from '../../shared/displayComponents/loading.component';
import { fetchEntities as fetchGlobalContextEntities } from '../../shared/store/actions';
import {
  useQueryStaticDataForForm,
  useQueryEntityOrgIdCountData,
} from '../../shared/queries/entitiesMaintenance';
import {
  useMutationCreateEntity,
  useMutationUpdateEntity,
} from '../../shared/mutations/entitiesMaintenance';

import EntityForm from './entityForm.component';
import entityPropTypes from './entity.propTypes';

const { OrganizationType, OrganizationTypeLabel, PARTNERSHIP_SOURCE } = entitySchemas;
const DEFAULT_COUNTRY_OF_INCORPORATION = 'US';

const organizationTypeOptions = Object.values(OrganizationType).map(val => ({
  value: val,
  label: OrganizationTypeLabel[val] || '',
}));
const partnershipSourceOptions = Object.values(PARTNERSHIP_SOURCE).map(el => ({
  value: el.value,
  label: el.label,
}));

const EntityFormContainer = ({ entityToEdit = null, entities = [], isFetchingEntities }) => {
  const dispatch = useDispatch();
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  const isContextReady = taxYear && period;
  const isEditMode = Boolean(entityToEdit);

  const { mutateAsync: createEntity, isLoading: isCreatingEntity } = useMutationCreateEntity();
  const { mutateAsync: updateEntity, isLoading: isUpdatingEntity } = useMutationUpdateEntity();

  const { data: formData, isFetching: isFetchingFormData } = useQueryStaticDataForForm({
    params: { taxYear, period },
    enabled: Boolean(taxYear && period),
  });

  const {
    isFetching: isFetchingEntityOrgIdCount,
    data: entityOrgIdCount,
  } = useQueryEntityOrgIdCountData({
    params: { taxYear, period, orgId: entityToEdit?.orgId },
    enabled: Boolean(taxYear && period),
  });

  const {
    businessDescriptionOptions,
    federalFilingTypeOptions,
    legalEntitiesOptions,
    jurisdictionOptions,
    orgRuleIdOptions,
    dcsHoLeidIndOptions,
    hoReportingPeriodOptions,
  } = formData;

  const defaultOrgRuleId = orgRuleIdOptions?.find(orgRule => orgRule.value === 'DEFAULT');

  const formInitialValues = {
    taxYear,
    orgId: '',
    legalEntityId: '',
    countryIncorporated: DEFAULT_COUNTRY_OF_INCORPORATION,
    orgType: OrganizationType.LEGAL_ENTITY,
    orgName: '',
    businessId: '',
    domicileJurisdictionId: '',
    fein: '',
    federalFilingType: '',
    orgRuleId: defaultOrgRuleId?.value || '',
    partnershipSource: null,
    autoFedRefresh: true,
    elimination: false,
    isActive: true,
    isDisregarded: false,
    entityType: null,
    existInEntityOps: null,
  };

  const save = useCallback(
    async (entity, { resetForm, setSubmitting }) => {
      setSubmitting(true);

      if (isEditMode) {
        await updateEntity({ period, entity });
      } else {
        await createEntity({ period, entity });
      }

      resetForm();
      setSubmitting(false);
      dispatch(fetchGlobalContextEntities(taxYear, period));
    },
    [dispatch, updateEntity, createEntity, isEditMode, taxYear, period],
  );

  const isLoading =
    !isContextReady ||
    isFetchingEntities ||
    isFetchingFormData ||
    isCreatingEntity ||
    isUpdatingEntity ||
    isFetchingEntityOrgIdCount;

  const renderForm = useCallback(
    formikProps => (
      <Loading isLoading={isLoading}>
        <EntityForm
          {...formikProps}
          businessDescriptionOptions={businessDescriptionOptions}
          legalEntitiesOptions={legalEntitiesOptions}
          federalFilingTypeOptions={federalFilingTypeOptions}
          isEditMode={isEditMode}
          jurisdictionOptions={jurisdictionOptions}
          organizationTypeOptions={organizationTypeOptions}
          entities={entities}
          orgRuleIdOptions={orgRuleIdOptions}
          partnershipSourceOptions={partnershipSourceOptions}
          dcsHoLeidIndOptions={dcsHoLeidIndOptions}
          hoReportingPeriodOptions={hoReportingPeriodOptions}
        />
      </Loading>
    ),
    [
      isLoading,
      businessDescriptionOptions,
      federalFilingTypeOptions,
      isEditMode,
      jurisdictionOptions,
      legalEntitiesOptions,
      entities,
      orgRuleIdOptions,
    ],
  );

  return (
    <Formik
      initialValues={
        entityToEdit
          ? {
              ...formInitialValues,
              ...entityToEdit,
              ...entityOrgIdCount,
            }
          : formInitialValues
      }
      validationSchema={entitySchemas.getCommon({
        isEditMode,
        filingMembers: entityToEdit?.filingMembers,
      })}
      enableReinitialize
      validateOnBlur={false}
      onSubmit={save}
    >
      {renderForm}
    </Formik>
  );
};

EntityFormContainer.propTypes = {
  entityToEdit: entityPropTypes,
  isFetchingEntities: PropTypes.bool.isRequired,
  entities: PropTypes.arrayOf(entityPropTypes),
};

export default EntityFormContainer;
