import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { ColumnTypes } from '@common-packages/shared-constants';
import { SelectOption } from '@tls/slt-types';
import { taxReturnsSchemas } from '@common-packages/validators';

import {
  TaxReturnResultRowWithCalcOptionsAndFilingAttributes,
  TaxReturnDefinitionWithFilingAttributes,
  FindTaxReturnsColumnsBlueprint,
  TaxReturnCalcPreferenceColumnBlueprint,
} from '../../../../../common/types/apiShapes';
import { globalContextSelector } from '../../store/selectors';
import { useQueryOrgProfile } from '../../queries/filingDecisions';
import { DisplayedAttributes, TaxReturnEFileStatus } from '../../enums';
import { FormValues } from '../utils/useFormInitialValues';
import TabWrapper from '../../displayComponents/tabWrapper/tabWrapper.component';
import { useQueryReturnStatus } from '../../queries/taxReturns';
import { useQueryDueDateOptions } from '../../queries/dueDates';
import { useQueryFindEntityFiscalYearEnd } from '../../queries/consolidations';
import { useShouldDisplayReturnsDueDatesWhenNotNonFiling } from '../../hooks/useShouldDisplayReturnsDueDates.hook';
import { useManualValidateForm } from '../../hooks/useManualValidateForm';
import { getOptionsByColumnGroupsAndTaxReturnDefinition } from '../utils/getOptionsByColumnGroupsAndTaxReturnDefinition';
import { getDefaultDueDateType } from '../utils/getDefaultDueDateType';

import { TabsTypes } from './addEditReturnPanel.container';
import isReturnDefinitionUsedInAnotherReturn from './isReturnDefinitionUsedInAnotherReturn';
import areReturnDefinitionFilingAttributesMatching from './areReturnDefinitionFilingAttributesMatching';
import AddEditReturnOptionsForm from './addEditReturnOptionsForm.component';
import AddEditCalcOptionsForm from './addEditCalcOptionsForm.container';
import useSetFilingAttribute from './hooks/useSetFilingAttribute.hook';
import useSetTaxType from './hooks/useSetTaxType.hook';

export const CALC_OPTIONS_KEY = 'calcOptions';

interface AddEditReturnFormWrapperProps {
  isEditing: boolean;
  activeTab: string;
  isLoadingTaxReturnsDefinitionsJurisdictionOptions: boolean;
  isLoadingTaxReturnsDefinitions: boolean;
  taxReturns: TaxReturnResultRowWithCalcOptionsAndFilingAttributes[];
  taxReturnsColumnsBlueprint: FindTaxReturnsColumnsBlueprint;
  taxReturnsDefinitions: TaxReturnDefinitionWithFilingAttributes[];
  taxReturnsDefinitionsJurisdictionOptions: SelectOption[];
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
}

const AddEditReturnFormWrapper = ({
  isEditing,
  activeTab,
  isLoadingTaxReturnsDefinitionsJurisdictionOptions,
  isLoadingTaxReturnsDefinitions,
  taxReturns,
  taxReturnsColumnsBlueprint,
  taxReturnsDefinitions,
  taxReturnsDefinitionsJurisdictionOptions,
  onNextButtonClick,
  onBackButtonClick,
}: AddEditReturnFormWrapperProps) => {
  const globalContext = useSelector(globalContextSelector);
  const { taxYear, period, entityId } = globalContext;

  const { values, setFieldValue } = useFormikContext<FormValues>();
  const shouldDisplayReturnsDueDatesWhenNotNonFiling = useShouldDisplayReturnsDueDatesWhenNotNonFiling();

  const { data: orgProfile, isFetching: isFetchingOrgProfile } = useQueryOrgProfile({
    params: {
      taxYear,
      period,
      jurisdictionId: values?.jurisdictionId,
      businessEntityId: entityId,
    },
    enabled: Boolean(taxYear && period && values?.jurisdictionId && entityId),
  });

  const { data: returnStatusOption } = useQueryReturnStatus({
    filingMethod: values.filingMethod,
    efileStatus: values.taxReturn?.efileStatus as TaxReturnEFileStatus,
    returnStatus: values.taxReturn?.returnStatus,
  });

  const { data: dueDateOption, isLoading: isLoadingDueDateOption } = useQueryDueDateOptions({
    params: {
      taxYear,
      period,
      businessEntityId: entityId,
      jurisdictionId: values.jurisdictionId || '',
      returnDefinitionId: values.returnDefinitionId || '',
    },
    enabled: Boolean(
      taxYear && period && entityId && values.jurisdictionId && values.returnDefinitionId,
    ),
  });

  const {
    data: entityFiscalYearEnd,
    isLoading: isLoadingEntityFiscalYearEnd,
  } = useQueryFindEntityFiscalYearEnd({
    params: {
      taxYear,
      period,
      businessEntityId: entityId,
    },
    enabled: Boolean(taxYear && period && entityId),
  });

  const stateFilingFormOptions = useMemo(
    () =>
      values.jurisdictionId
        ? taxReturnsDefinitions
            ?.filter(({ jurisdictionId }) => jurisdictionId === values.jurisdictionId)
            .filter(
              returnDefinition =>
                isEditing ||
                (!isReturnDefinitionUsedInAnotherReturn(returnDefinition, taxReturns) &&
                  areReturnDefinitionFilingAttributesMatching(returnDefinition, taxReturns)),
            )
            .map(({ name: label, id: value, isDefault, filingAttributesNumber }) => ({
              label,
              value,
              isDefault,
              filingAttributesNumber,
            }))
        : [],
    [values.jurisdictionId, taxReturnsDefinitions, taxReturns, isEditing],
  );

  const taxReturnsDefinition = useMemo(
    () => taxReturnsDefinitions?.find(({ id }) => id === values.returnDefinitionId),
    [taxReturnsDefinitions, values.returnDefinitionId],
  );

  const existingTaxReturnsForJurisdiction = useMemo(
    () => taxReturns.filter(({ jurisdictionId }) => jurisdictionId === values.jurisdictionId),
    [values.jurisdictionId, taxReturns],
  );

  const calcPreferences = (useMemo(
    () =>
      taxReturnsColumnsBlueprint.filter(
        column => column.columnType === ColumnTypes.CALC_PREFERENCES,
      ),
    [taxReturnsColumnsBlueprint],
  ) as unknown) as TaxReturnCalcPreferenceColumnBlueprint[];

  const returnDefinitionFilingAttributes = useMemo(
    () =>
      getOptionsByColumnGroupsAndTaxReturnDefinition({
        taxReturnsColumnsBlueprint,
        taxReturnsDefinition,
      }),
    [taxReturnsColumnsBlueprint, taxReturnsDefinition],
  );

  const {
    taxReturnDefinitionEnabledFilingAttributeOptions: taxReturnDefinitionEnabledReturnTypeOptions,
    isFilingAttributeDisabled: isReturnTypeSelectDisabled,
  } = useSetFilingAttribute({
    filingAttribute: DisplayedAttributes.RETURN_TYPE,
    isEditing,
    existingTaxReturnsForJurisdiction,
    returnDefinitionFilingAttributes,
  });

  const {
    taxReturnDefinitionEnabledFilingAttributeOptions: taxReturnDefinitionEnabledBusinessTypeOptions,
    isFilingAttributeDisabled: isBusinessTypeSelectDisabled,
  } = useSetFilingAttribute({
    filingAttribute: DisplayedAttributes.BUSINESS_TYPE,
    isEditing,
    existingTaxReturnsForJurisdiction,
    returnDefinitionFilingAttributes,
  });

  useSetFilingAttribute({
    filingAttribute: DisplayedAttributes.FILING_TYPE,
    isEditing,
    existingTaxReturnsForJurisdiction,
    returnDefinitionFilingAttributes,
  });

  useManualValidateForm(
    taxReturnsSchemas.getAddEditTaxReturnSchema({
      shouldDisplayDueDate: shouldDisplayReturnsDueDatesWhenNotNonFiling,
      dueDateOption,
      stateFilingFormOptions,
      entityFiscalYearEnd: entityFiscalYearEnd?.fiscalYearEnd,
      taxReturnDefinitionEnabledReturnTypeOptions,
      taxReturnDefinitionEnabledBusinessTypeOptions,
    }),
  );

  const { areTaxTypeCheckboxesDisabled } = useSetTaxType({
    isEditing,
    taxReturnsDefinition,
    existingTaxReturnsForJurisdiction,
    returnDefinitionFilingAttributes,
  });

  useEffect(() => {
    // clean up selection after changing jurisdiction id
    if (!isEditing) {
      setFieldValue('returnDefinitionId', '');
      setFieldValue('filingMethod', '');
    }
  }, [setFieldValue, isEditing, values.jurisdictionId]);

  useEffect(() => {
    const stateFilingForm = stateFilingFormOptions?.find(({ isDefault }) => isDefault)?.value;
    if (isEditing || !stateFilingForm) {
      return;
    }
    setFieldValue(
      'returnDefinitionId',
      stateFilingFormOptions?.find(({ isDefault }) => isDefault)?.value,
    );
  }, [setFieldValue, isEditing, stateFilingFormOptions]);

  useEffect(() => {
    if (!isEditing) {
      setFieldValue(CALC_OPTIONS_KEY, orgProfile?.calcOptions || {});
    }
  }, [setFieldValue, isEditing, orgProfile]);

  useEffect(() => {
    if (
      isEditing ||
      !dueDateOption ||
      !dueDateOption.length ||
      !shouldDisplayReturnsDueDatesWhenNotNonFiling
    ) {
      return;
    }
    setFieldValue('dueDateType', getDefaultDueDateType(dueDateOption));
  }, [isEditing, dueDateOption, shouldDisplayReturnsDueDatesWhenNotNonFiling, setFieldValue]);

  const isLoadingReturnOptions =
    isLoadingTaxReturnsDefinitionsJurisdictionOptions ||
    isLoadingTaxReturnsDefinitions ||
    isLoadingEntityFiscalYearEnd;

  const isLoadingCalcOptions =
    isFetchingOrgProfile || !returnDefinitionFilingAttributes || !calcPreferences;

  return (
    <>
      <TabWrapper isActiveTab={activeTab === TabsTypes.RETURN_OPTIONS}>
        <AddEditReturnOptionsForm
          isEditing={isEditing}
          isLoading={isLoadingReturnOptions}
          taxReturnsDefinitionsJurisdictionOptions={taxReturnsDefinitionsJurisdictionOptions}
          stateFilingFormOptions={stateFilingFormOptions}
          taxReturnsDefinition={taxReturnsDefinition}
          dueDateOption={dueDateOption}
          isLoadingDueDateOption={isLoadingDueDateOption}
          returnStatusOption={returnStatusOption}
          onNextButtonClick={onNextButtonClick}
        />
      </TabWrapper>
      <TabWrapper isActiveTab={activeTab === TabsTypes.CALC_OPTIONS}>
        <AddEditCalcOptionsForm
          isEditing={isEditing}
          isLoading={isLoadingCalcOptions}
          isReturnTypeSelectDisabled={isReturnTypeSelectDisabled}
          isBusinessTypeSelectDisabled={isBusinessTypeSelectDisabled}
          areTaxTypeCheckboxesDisabled={areTaxTypeCheckboxesDisabled}
          hasExistingTaxReturnsForJurisdiction={Boolean(existingTaxReturnsForJurisdiction.length)}
          calcPreferences={calcPreferences}
          returnDefinitionFilingAttributes={returnDefinitionFilingAttributes}
          taxReturnDefinitionEnabledReturnTypeOptions={taxReturnDefinitionEnabledReturnTypeOptions}
          taxReturnDefinitionEnabledBusinessTypeOptions={
            taxReturnDefinitionEnabledBusinessTypeOptions
          }
          onBackButtonClick={onBackButtonClick}
        />
      </TabWrapper>
    </>
  );
};

export default AddEditReturnFormWrapper;
