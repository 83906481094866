import { useQuery, QueryFunctionContext, QueryKey } from 'react-query';
import { StatusCodes } from 'http-status-codes';

import { httpGetAndParse } from '../../utils/fetchService';
import { successNotification, errorNotification } from '../notification/store/actions';
import store from '../../store';

import { createValidateQueryKey, useAutoMemo, validateQueryKeys } from './utils';

export const QueryKeys = Object.freeze({
  Binders: {
    SltBinderOptions: 'Binders_SltBinderOptions',
    BinderData: 'Binders_BinderData',
  },
  CommonDataModels: {
    CommonDataModels: 'CommonDataModels_CommonDataModels',
  },
  Watermarks: {
    Watermarks: 'Watermarks_Watermarks',
    Preview: 'Watermarks_Preview',
  },
  ContentDeployment: {
    ContentDeployments: 'ContentDeployment_ContentDeployments',
  },
  CustomReports: {
    RunParamQuery: 'CustomReports_RunParamQuery',
  },
  TaxFormsV2: {
    FormsByWatermark: 'TaxFormsV2_FormsByWatermark',
    SuppressFieldDatasets: 'TaxFormsV2_SuppressFieldDatasets',
    Forms: 'TaxFormsV2_Forms',
  },
  K1InformationV2: {
    FilterData: 'K1InformationV2_FilterData',
    FormFields: 'K1InformationV2_FormFields',
  },
  GlobalK1Information: {
    FormData: 'GlobalK1Information_FormData',
  },
  FilingGroups: {
    FilingGroups: 'FilingGroups_FilingGroups',
    FilingMembers: 'FilingGroups_FilingMembers',
    ReturnOptions: 'FilingGroups_ReturnOptions',
    OverlappingEntities: 'FilingGroups_OverlappingEntities',
    ConsolidationsByGroupId: 'FilingGroups_ConsolidationsByGroupId',
    ConsolidatedGroupReturns: 'FilingGroups_ConsolidatedGroupReturns',
  },
  SelectionLists: {
    SelectionLists: 'SelectionLists_SelectionLists',
    SelectionListsByJurisdiction: 'SelectionLists_SelectionListsByJurisdiction',
    SelectionListsDefaultValues: 'SelectionLists_SelectionListsDefaultValues',
    DataUsingSelectionList: 'SelectionLists_DataUsingSelectionList',
  },
  EntitiesMaintenance: {
    Entities: 'EntitiesMaintenance_Entities',
    FormData: 'EntitiesMaintenance_FormData',
    EntityData: 'EntitiesMaintenance_EntityData',
  },
  MemberInformationalData: {
    Forms: 'MemberInformationalData_Forms',
    Members: 'MemberInformationalData_Members',
    Fields: 'MemberInformationalData_Fields',
  },
  GlobalMemberInformationalData: {
    Fields: 'GlobalMemberInformationalData_Fields',
  },
  IndividualMaintenance: {
    Fields: 'IndividualMaintenance_Fields',
  },
  ConsolidationFilingEntities: {
    Data: 'ConsolidationFilingEntities_Data',
    EntitiesOptions: 'ConsolidationFilingEntities_EntitiesOptions',
    EntityFiscalYearEnd: 'ConsolidationFilingEntities_EntityFiscalYearEnd',
  },
  ConsolidationFilingGroups: {
    Data: 'ConsolidationFilingGroups_Data',
  },
  ConsolidationConsolidatedReport: {
    Forms: 'ConsolidationConsolidatedReport_Forms',
  },
  ConsolidationInformationalData: {
    Forms: 'ConsolidationInformationalData_Forms',
    Fields: 'ConsolidationInformationalData_Fields',
  },
  ConsolidationInfo: {
    GlobalInfo: 'ConsolidationInfo_GlobalInfo',
    CopyGlobalInfomationalDataFromParentToConsolidation:
      'ConsolidationInfo_CopyGlobalInfomationalDataFromParentToConsolidation',
  },
  Csrf: {
    CsrfToken: 'Csrf_CsrfToken',
  },
  Auth: {
    Token: 'Auth_Token',
  },
  DueDates: {
    DueDateOptions: 'DueDates_DueDateOptions',
  },
  StateCommonInformation: {
    CommonDataModelFields: 'StateCommonInformation_CommonDataModelFields',
  },
  ViewTaxReturn: {
    FieldDrillInfo: 'ViewTaxReturn_FieldDrillInfo',
  },
  WorkpaperInstance: {
    Data: 'WorkpaperInstance_Data',
  },
  DataModels: {
    DatasetInstances: 'DataModels_DatasetInstances',
    DataModels: 'DataModels_DataModels',
    DataSets: 'DataModels_DataSets',
    DataItems: 'DataModels_DataItems',
  },
  GenericCategory: {
    ComponentOptions: 'GenericCategory_ComponentOptions',
  },
  EFile: {
    GroupSelection: 'EFile_GroupSelection',
    TransmissionErrors: 'EFile_TransmissionErrors',
    TtiCaseId: 'EFile_TtiCaseId',
    TtiYearId: 'EFile_TtiYearId',
    TtiBinders: 'EFile_TtiBinders',
    SignatureDeclaration: 'EFile_SignatureDeclaration',
  },
  EFileV2: {
    GetSubmissionFiles: 'EFileV2_GetSubmissionFiles',
    /**
     * InitialWizard - Used only for initial state of wizard
     *
     * We use 'Main' to avoid re-loading (spinner) the entire wizard UI
     */
    GetFederalReturn: {
      InitialWizard: 'EFileV2_GetFederalReturn_InitialWizard',
      Main: 'EFileV2_GetFederalReturn_Main',
    },
    Validation: 'EFileV2_Validation',
    GetSteps: 'EFileV2_GetSteps',
    GetStepsStatus: 'EFileV2_GetStepsStatus',
    GetValidationErrors: 'EFileV2_GetValidationErrors',
    GetAuthorizationResponse: 'EFileV2_GetAuthorizationResponse',
    GetTrRejectionMessages: 'EFileV2_GetTrRejectionMessages',
    GetSignatureDeclaration: 'EFileV2_GetSignatureDeclaration',
    GetReturnValidationResults: 'EFileV2_GetReturnValidationResults',
    FederalReturnOption: 'EFileV2_FederalReturnOption',
    GetTransmissionStatus: 'EFileV2_GetTransmissionStatus',
    GetHistoryTransmission: 'EFileV2_GetHistoryTransmission',
    TtiGroupValidation: 'EFileV2_TtiGroupValidation',
    GetCheckTtiServiceRunning: 'EFileV2_GetCheckTtiServiceRunning',
    GetFederalReturnsAvailability: 'EFileV2_GetFederalReturnsAvailability',
    GetManualFederalProformaSubmissionIdOptions:
      'EFileV2_GetManualFederalProformaSubmissionIdOptions',
    GetProformaImportIrsAcceptedResult: 'EFileV2_GetProformaImportIrsAcceptedResult',
  },
  Diagnostics: {
    Data: 'Diagnostics_Data',
  },
  ExpressionBuilder: {
    CompilationInfo: 'ExpressionBuilder_CompilationInfo',
    DisallowOverrideReport: 'ExpressionBuilder_DisallowOverrideReport',
  },
  BinderMaintenance: {
    LatestPdfBinderGenerationJob: 'BinderMaintenance_LatestPdfBinderGenerationJob',
    ReturnAttachments: 'BinderMaintenance_ReturnAttachments',
    BinderPdf: 'BinderMaintenance_BinderPdf',
    ConsolidationEntityFormsByPeriod: 'BinderMaintenance_ConsolidationEntityFormsByPeriod',
  },
  FilingDecisions: {
    OrgProfileData: 'FilingDecisions_OrgProfileData',
    ConsolidationData: 'FilingDecisions_ConsolidationData',
  },
  TaxReturns: {
    Data: 'TaxReturns_Data',
    ReturnOptions: 'TaxReturns_ReturnOptions',
    ReturnDefinitions: 'TaxReturns_ReturnDefinitions',
    ReturnDefinitionsOptions: 'TaxReturns_ReturnDefinitionsOptions',
    ReturnItems: 'TaxReturns_ReturnItems',
    ReturnStatuses: 'TaxReturns_ReturnStatuses',
    DueDateOptions: 'TaxReturns_DueDateOptions',
    OrgType: 'TaxReturns_OrgType',
    TaxReturn: 'TaxReturns_TaxReturn',
  },
  ReturnStatusTackerDetails: {
    Data: 'ReturnStatusTackerDetails_Data',
  },
  MemberReturns: {
    Data: 'MemberReturns_Data',
  },
  ConsolidationsMaintenance: {
    Data: 'ConsolidationsMaintenance_Data',
  },
  CheckboxGroups: {
    Data: 'CheckboxGroups_Data',
    DataItemsUsingCheckboxGroup: 'CheckboxGroups_DataItemsUsingCheckboxGroup',
  },
  Notifications: {
    Data: 'Notifications_Data',
  },
  CalcPreferences: {
    CalcPreferences: 'CalcPreferences_CalcPreferences',
  },
  EFileMapping: {
    SchemaIdOptions: 'EFileMapping_SchemaIdOptions',
    DatasetMappings: 'EFileMapping_DatasetMappings',
  },
  ReturnDefinitions: {
    ReturnDefinitions: 'ReturnDefinitions_ReturnDefinitions',
  },
  FilingAttributes: {
    Definitions: 'FilingAttributes_Definitions',
  },
  Consolidations: {
    Data: 'Consolidations_Data',
    FetchConsolidations: 'Consolidations_FetchConsolidations',
  },
  Shared: {
    Forms: 'Shared_Forms',
  },
  EFileSupport: {
    ReturnOptions: 'EFileSupport_ReturnOptions',
    EFileBinderFilingsData: 'EFileSupport_ReturnAdditionalInformation',
    EfileBinderItemsCount: 'EFileSupport_FindBinderItemsCount',
    BinderSteps: 'EFileSupport_BinderSteps',
    TransmitterSetup: 'EFileSupport_TransmitterSetup',
    XmlMappingElements: 'EFileSupport_XmlMappingElements',
    StepDetails: 'EFileSupport_StepDetails',
    UpdateSelectedSubstepStatus: 'EFileSupport_UpdateSelectedSubstepStatus',
    UpdateSelectedTransmitter: 'EFileSupport_UpdateSelectedTransmitter',
    ResetReturnEFileStatus: 'EFileSupport_ResetReturnEFileStatus',
  },
  ManualCalculations: {
    DREMap: 'ManualCalculations_DREMap',
    FederalInfo: 'ManualCalculations_FederalInfo',
    CheckEntityCalcPreference: 'ManualCalculations_CheckEntityCalcPreference',
  },
  FederalDataDiagnostics: {
    RecentRefreshes: 'FederalDataDiagnostics_RecentRefreshes',
    Status: 'FederalDataDiagnostics_Status',
    Details: 'FederalDataDiagnostics_Details',
    Refresh: 'FederalDataDiagnostics_Refresh',
    FilingGroup: 'FederalDataDiagnostics_FilingGroup',
    FilingGroupSendMessage: 'FederalDataDiagnostics_FilingGroupSendMessage',
  },
  FederalProforma: {
    FederalPdf: 'FederalProforma_FederalPdf',
    FerderalFileDelete: 'FederalProforma_FerderalFileDelete',
  },
  Attachments: {
    DownloadAttachment: 'Attachments_DownloadAttachment',
    Attachments: 'Attachments_Attachments',
  },
  EntityInformationDiagnostics: {
    RecentRefreshes: 'EntityInformationDiagnostics_RecentRefreshes',
    AllEntities: 'EntityInformationDiagnostics_AllEntities',
    Status: 'EntityInformationDiagnostics_Status',
    Details: 'EntityInformationDiagnostics_Details',
    DetailsLogs: 'EntityInformationDiagnostics_DetailsLogs',
    Refresh: 'EntityInformationDiagnostics_Refresh',
    FilingGroup: 'EntityInformationDiagnostics_FilingGroup',
    FilingGroupSendMessage: 'EntityInformationDiagnostics_FilingGroupSendMessage',
  },
  Reports: {
    EFileXmlComparisonReport: 'Reports_EFileXmlComparisonReport',
  },
  Utilities: {
    AddBinderDefaultForms: 'Utilities_AddBinderDefaultForms',
  },
  InstanceDataCleanup: {
    DataModels: 'InstanceDataCleanup_DataModels',
    DatasetDefinitions: 'InstanceDataCleanup_DatasetDefinitions',
    DatasetInstances: 'InstanceDataCleanup_DatasetInstances',
  },
});

validateQueryKeys(QueryKeys);
const validateKey = createValidateQueryKey(QueryKeys);

export interface OnQuerySuccessCallback<TData> {
  (data: TData): void;
}

export interface OnQueryErrorCallback<TError> {
  (error: TError): void;
}

interface CustomQueryOptionsBase<TData, TError, TSelectorData> {
  initialData?: TData;
  placeholderData?: TData;
  defaultData?: TSelectorData;
  onSuccess?: OnQuerySuccessCallback<TSelectorData>;
  onError?: OnQueryErrorCallback<TError>;
  errorMessage?: string;
  successMessage?: string;
  enabled?: boolean;
  refetchInterval?: number | false;
  displayMessageFromError?: boolean;
  selector?: (data: TData) => TSelectorData;
  cacheTime?: number;
}

interface CustomQueryOptionsWithUrl<TData, TError, TSelectorData>
  extends CustomQueryOptionsBase<TData, TError, TSelectorData> {
  url: string;
  customFetch?: null;
}
interface CustomQueryOptionsWithCustomFetch<TData, TError, TSelectorData>
  extends CustomQueryOptionsBase<TData, TError, TSelectorData> {
  url?: null;
  customFetch: (queryContext: QueryFunctionContext) => TData | Promise<TData>;
}

type CustomQueryOptions<TData, TError, TSelectorData = TData> =
  | CustomQueryOptionsWithUrl<TData, TError, TSelectorData>
  | CustomQueryOptionsWithCustomFetch<TData, TError, TSelectorData>;

export type QueryError = Error & {
  message: string;
  isAuthorizationError?: boolean;
  isFetchingError?: boolean;
  status?: number;
};

const isQueryError = (error: Error | QueryError): error is QueryError =>
  (typeof (error as QueryError).isAuthorizationError !== 'undefined' ||
    typeof (error as QueryError).isFetchingError !== 'undefined') &&
  typeof (error as QueryError).message !== 'undefined';

const createUseCustomQuery = ({
  defaultFetch = () => null,
  displaySuccessNotification = () => null,
  displayErrorNotification = () => null,
}: {
  defaultFetch: (url?: string | null) => unknown;
  displaySuccessNotification: (message?: string) => unknown;
  displayErrorNotification: (message?: string) => unknown;
}) => <TData, TError extends Error | QueryError = QueryError, TSelectorData = TData>(
  key: QueryKey,
  {
    url,
    initialData,
    placeholderData,
    defaultData: defaultDataRaw,
    onSuccess: onSuccessCallback,
    onError: onErrorCallback,
    successMessage,
    errorMessage,
    enabled,
    refetchInterval,
    displayMessageFromError = false,
    customFetch,
    selector,
    cacheTime,
  }: CustomQueryOptions<TData, TError, TSelectorData>,
) => {
  const defaultData = useAutoMemo(defaultDataRaw);

  const queryStatus = useQuery<TData, TError, TSelectorData>(
    key,
    queryContext => {
      validateKey(Array.isArray(key) ? key[0] : key);
      if (customFetch) {
        return customFetch(queryContext);
      }
      return defaultFetch(url) as TData;
    },
    {
      enabled,
      initialData,
      placeholderData,
      refetchInterval,
      cacheTime,
      select: selector,
      onSuccess: data => {
        if (successMessage) {
          displaySuccessNotification(successMessage);
        }
        if (onSuccessCallback) {
          onSuccessCallback(data);
        }
      },
      retry: (failureCount, error) => {
        if (isQueryError(error) && error?.status === StatusCodes.NOT_FOUND) {
          return false;
        }
        return failureCount < 4;
      },
      onError: error => {
        if (onErrorCallback) {
          onErrorCallback(error);
        }
        if (isQueryError(error)) {
          if (error.isAuthorizationError) {
            return;
          }
          if (error.isFetchingError && !displayMessageFromError && errorMessage) {
            return displayErrorNotification(errorMessage);
          }
          if (error.isFetchingError && displayMessageFromError && error.message) {
            return displayErrorNotification(error.message);
          }
        }
        throw error;
      },
    },
  );

  return {
    ...queryStatus,
    data:
      typeof defaultData !== 'undefined' && typeof queryStatus.data === 'undefined'
        ? defaultData
        : queryStatus.data,
  };
};

export const useCustomQuery = createUseCustomQuery({
  defaultFetch: url => httpGetAndParse({ route: url }),
  displaySuccessNotification: message => store.dispatch(successNotification(message)),
  displayErrorNotification: message => store.dispatch(errorNotification(message)),
});
