export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum FilingMethods {
  EFILE = 'efile',
  PAPER = 'paper',
  NON_FILING = 'non-filing',
  NONE = '',
}

export enum FilingMethodsIndex {
  EFILE,
  PAPER,
  NON_FILING,
  NONE,
}

export enum FederalReturnAttachments {
  LINKED = '1',
  UNLINKED = '2',
  NO_XML = '3',
  NONE = '',
}

// this is a copy of `DisplayedAttributes` from common/types/apiShapes directory
// the ones from common directory can't be used at runtime because they fall out of project scope
export enum DisplayedAttributes {
  RETURN_TYPE = 'RETURN_TYPE',
  BUSINESS_TYPE = 'BUSINESS_TYPE',
  TAX_TYPE = 'TAX_TYPE',
  FILING_TYPE = 'FILING_TYPE',
}

export enum DisplayedReturnTypeAttributes {
  RETURN_TYPE_1120 = 'RETURN_TYPE_1120',
  RETURN_TYPE_1065 = 'RETURN_TYPE_1065',
  RETURN_TYPE_DRE = 'RETURN_TYPE_DRE',
  RETURN_TYPE_LLC = 'RETURN_TYPE_LLC',
  RETURN_TYPE_SMLLC = 'RETURN_TYPE_SMLLC',
  RETURN_TYPE_1120PC = 'RETURN_TYPE_1120PC',
  RETURN_TYPE_1120L = 'RETURN_TYPE_1120L',
}

export enum DisplayedBusinessTypeAttributes {
  BUSINESS_TYPE_FINANCIAL = 'BUSINESS_TYPE_FINANCIAL',
  BUSINESS_TYPE_GENERAL = 'BUSINESS_TYPE_GENERAL',
  BUSINESS_TYPE_INSURANCE = 'BUSINESS_TYPE_INSURANCE',
  BUSINESS_TYPE_OIL_AND_GAS = 'BUSINESS_TYPE_OIL_AND_GAS',
  BUSINESS_TYPE_REGULATED_EXCHANGE = 'BUSINESS_TYPE_REGULATED_EXCHANGE',
  BUSINESS_TYPE_TRANSPORTATION = 'BUSINESS_TYPE_TRANSPORTATION',
}

export enum DisplayedTaxTypeAttributes {
  TAX_TYPE_INCOME = 'TAX_TYPE_INCOME',
  TAX_TYPE_FRANCHISE = 'TAX_TYPE_FRANCHISE',
}

export enum DisplayedTaxTypeAttributesForDropdown {
  TAX_TYPE_INCOME = 'TAX_TYPE_INCOME',
  TAX_TYPE_FRANCHISE = 'TAX_TYPE_FRANCHISE',
  TAX_TYPE_ALL = 'TAX_TYPE_ALL',
}

export enum FilingTypes {
  SEPARATE = 'SEPARATE',
  CONSOLIDATED = 'CONSOLIDATED',
}

// TODO: SLT-12178 Consolidate DataTypes enums/constants
export enum DataTypes {
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  AMOUNT = 'AMOUNT',
}

export enum TaxReturnStatus {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  COMPLETE = 3,
}

export enum TaxReturnEFileStatus {
  VALIDATING = 1,
  ERRORS_PENDING = 2,
  READY_FOR_REVIEW = 3,
  TRANSMITTED = 4,
  REJECTED = 5,
  DENIED = 6,
  ACCEPTED = 7,
  NotRequested = 8,
  NotQualified = 9,
  Qualified = 10,
  Requested = 11,
  Running = 12,
  AwaitingAcknowledgement = 13,
  ConditionallyAccepted = 14,
  FederalRejected = 15,
  DiagnosticsExist = 16,
  NotSubmitted = 50,
}

export enum DueDateOptionsDesc {
  DueDate = 'Due Date',
  ExtendedDueDate = 'Extended Due Date',
  secondExtendedDueDate = '2nd Extended Due Date',
}

export enum AliasTypes {
  Account = 'A',
  InfoAccount = 'I',
  Category = 'C',
}

export enum ContextType {
  TAX_YEAR_TYPE = 'TAX_YEAR',
  JURISDICTION_ID_TYPE = 'JURISDICTION_ID',
  SLT_SCHEMA_ID_TYPE = 'SLT_SCHEMA_ID',
}
